import React from "react";

export default function Timer(props) {
  const time = props.time;

  const seconds = time % 60;
  const minutes = (time - seconds) / 60;

  const getLabel = (value) => {
    return value > 9 ? `${value}` : `0${value}`;
  };

  const secondsLabel = getLabel(seconds);
  const minutesLabel = getLabel(minutes);

  return (
    <div className="text-[10rem] 2xl:text-[15rem] font-bold text-center font-mono">
      {`${minutesLabel}:${secondsLabel}`}
    </div>
  );
}
