import React, { useEffect, useState } from "react";
import { FaRedo, FaPlus, FaMinus } from "react-icons/fa";

import Timer from "../components/timer";

export default function Main() {
  const defaultTime = 600;
  const [timeLeft, setTimeLeft] = useState(defaultTime);
  const [timerIsRunning, setTimerIsRunning] = useState(false);

  const addMinute = () => {
    setTimeLeft(timeLeft + 60);
  };

  const removeMinute = () => {
    if (timeLeft > 60) {
      setTimeLeft(timeLeft - 60);
    } else {
      resetTimer();
    }
  };

  const resetTimer = () => {
    setTimerIsRunning(false);
    setTimeLeft(defaultTime);
  };

  useEffect(() => {
    if (timerIsRunning && timeLeft > 0) {
      let interval = setInterval(() => setTimeLeft(timeLeft - 1), 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [timeLeft, timerIsRunning]);

  return (
    <div className="hero min-h-screen bg-white">
      <div className="min-h-full w-full flex flex-col">
        <div className="flex-none w-full pt-2 pr-2 flex justify-end">
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              className={`toggle ${timerIsRunning ? "opacity-10" : ""}`}
              checked={timerIsRunning}
              onChange={() => setTimerIsRunning(!timerIsRunning)}
            />
            <button className="opacity-20" onClick={addMinute}>
              <FaPlus />
            </button>
            <button className="opacity-20" onClick={removeMinute}>
              <FaMinus />
            </button>
            <button className="opacity-20" onClick={resetTimer}>
              <FaRedo />
            </button>
          </div>
        </div>
        <div className="grow flex justify-center items-center w-full text-center">
          <div>
            <div className="space-y-4">
              <img
                src="/kodl-logo.png"
                alt="Galerie Kodl"
                width={400}
                className="mx-auto"
              />
              <h1 className="text-5xl">
                {timeLeft > 0
                  ? "Aukce bude pokračovat po přestávce za"
                  : "Konec přestávky"}
              </h1>
            </div>
            {timeLeft > 0 && <Timer time={timeLeft} />}
          </div>
        </div>
      </div>
    </div>
  );
}
